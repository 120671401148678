'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.devices
 # @description

###
angular
  .module 'mundoAdmin.devices', [
    'ui.router'
  ]
